<template>
  <div class="basic">
    <v-row no-gutters  align="center" justify="center" >
      <v-col cols="12">

        <v-card flat class="pa-4 ma-3">
          <v-row>
            <v-col md="4" cols='12'>
              <v-container fluid v-if="loading" class="ma-10">
                <v-row dense>
                  <v-col cols="12">
                    <v-row class="align-center justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <canvas id="supplement_" :class="{ mobile: mobile }" />
            </v-col>
            <v-col cols='8'>
              <v-row dense>
                <v-col cols="3" md="3" class="subtitle-2 pt-5" align="center" >
                  <div class="info_title">칼로리</div>
                  <div class="recommended">{{ Math.round(calc_calory).format() }}</div>
                  <div class="unit">Kcal</div>
                </v-col>
                <v-col cols="3" md="3" class="subtitle-2 pt-5" align="center" >
                  <div class="info_title">탄수화물</div>
                  <div class="recommended">{{ calc_carb }}</div>
                  <div class="unit">g</div>
                </v-col>
                <v-col cols="3" md="3" class="subtitle-2 pt-5" align="center" >
                  <div class="info_title">단백질</div>
                  <div class="recommended">{{ calc_protein }}</div>
                  <div class="unit">g</div>
                </v-col>
                <v-col cols="3" md="3" class="subtitle-2 pt-5" align="center" >
                  <div class="info_title">지방</div>
                  <div class="recommended">{{ calc_fat }}</div>
                  <div class="unit">g</div>
                </v-col>
              </v-row>

              <v-card flat class="pa-4 ma-3 mt-5" color="transparent" >
                <v-row dense>
                  <v-col cols="12" class="font-weight-bold">
                    커스텀 음식으로 넣기
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="10" class="ma-0 pa-2">
                    <v-text-field
                      label="커스텀 음식명 입력"
                      v-model="custom_food_title"
                      outlined
                      class="ma-0 pa-0"
                      hint="현재 보충제 정보를 커스텀 음식으로 저장합니다."
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="ma-0 pa-2">
                    <v-btn fab medium elevation=0 @click="add_custom">
                      <v-icon large>add</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>

            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-row dense>
            <v-col cols="12" class="title pa-2 font-weight-bold">
              혼합 목록
            </v-col>
          </v-row>
          <v-row dense>
            <v-col sm="6" cols="12" v-for="(each, i) in foods" :key="i">
              <v-card outlined class="elevation-0 pa-3 ma-3 mt-0 mb-0">
                <v-row dense>
                  <v-col cols="12" class="subtitle-2">
                    <v-row dense>
                      <v-col cols="10">
                        {{ each.name }} x {{ each.amount }}
                      </v-col>
                      <v-col cols="2">
                        <v-btn xSmall color="#FBDE44FF" class="elevation-0 op_btn" @click="deleteItem(i)" >
                          <v-icon color="black" small>close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="caption">{{ (Math.round( ( each.carb*each.amount*4 + each.protein*each.amount*4 + each.fat*each.amount*9)*10)/10).format() }}Kcal</v-col>
                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #70AD47">{{ each.carb.format() }}g</v-col>
                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #4472C7">{{ each.protein.format() }}g</v-col>
                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #ED7D31">{{ each.fat.format() }}g</v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>


    <v-dialog
      v-model="dialog"
      :fullscreen="mobile"
      hide-overlay
      transition="fade-transition"
      @click:outside="close"
      style="overflow:auto;-webkit-overflow-scrolling:touch"
      width="800">
      <template v-slot:activator="{ on }">
        <v-btn class="mx-3 ma-3" color="black" fab v-on="on" elevation=0 :class="{mobile_input:mobile, desktop_input:!mobile}">
          <v-icon color="#FBDE44FF" x-large>add</v-icon>
        </v-btn>
      </template>
      <v-card class="pa-0 ma-0 " style="background-color:#f5f5f5;">

        <v-toolbar dark color="#FBDE44FF" v-if="mobile" class="mb-5">
          <v-btn icon color="black" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="black--text">보충제 추가</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="black" text :disabled="!food_selected || !editedItem.amount"  @click="save2">저장 후 추가</v-btn>
            <!--<v-btn color="black" text @click="save">저장</v-btn>-->
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text no-gutters class="pb-0">

          <template class="overflow-y-auto" v-if="editedIndex">
            <v-card flat style="background-color:#f5f5f5;">
              <v-row no-gutters>
                <v-col cols="12" class="pt-5 pb-5">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="검색"
                    outlined
                    single-line
                    hide-details
                    class="dialog_search"
                    style="background-color:white;"
                  ></v-text-field>
                </v-col>
                <v-col sm="8" cols="12" style="background-color:white;border:1px solid #dddddd">
                  <v-data-table
                    class="dialog_table mt-5"
                    :headers="rawheaders"
                    :items="rawfoods"
                    :search="search"
                    @click:row="selectRawFood"
                    :items-per-page=5
                    :mobile-breakpoint=NaN
                    dense
                    no-data-text="목록이 없습니다."
                    no-results-text="검색 결과가 없습니다."
                    :footer-props="{
                      itemsPerPageText: ''
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr @click="selectRawFood(item)">
                        <td>
                          <v-icon v-if="item.custom" color="red" small>star</v-icon>
                          {{item.name}}
                        </td>
                        <td align=right>{{(item.standard === undefined ) ? 100: item.standard}} {{item.unit}}</td>
                        <td align=right>{{item.carb}}</td>
                        <td align=right>{{item.protein}}</td>
                        <td align=right>{{item.fat}}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>

                <v-col sm="4" cols="12" class="ma-0 pa-0">
                  <v-row no-gutters>

                    <v-col sm="12" cols="6"  class="subtitle-2">
                      <v-card outlined :class="{ 'elevation-0':1, 'pa-3':1, 'pt-1': 1, 'pb-2': 1, 'mb-2': !mobile, 'ml-2': !mobile, 'mt-2': mobile, 'mr-1': mobile }">
                        <v-row dense>
                          <v-col cols="12" class="subtitle-2">
                            <v-row dense>
                              <v-col cols="12">
                                {{ editedItem.name?editedItem.name:"식품명" }}
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #70AD47">
                            {{ editedItem.carb.format() }}g
                          </v-col>
                          <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #4472C7">
                            {{ editedItem.protein.format() }}g
                          </v-col>
                          <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #ED7D31">
                            {{ editedItem.fat.format() }}g
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>

                    <v-col sm="12" cols="6" class="subtitle-2 big2">
                      <v-text-field
                        style="background-color:white;border:1px solid #dddddd"
                        v-model.number="editedItem.amount"
                        :disabled="!food_selected"
                        :class="{ 'center-input':1, 'pa-3':1, 'pt-6': 1, 'pb-1': 1, 'mb-2': !mobile, 'ml-2': !mobile, 'mt-2': mobile, 'ml-1': mobile }"
                        label="수량"
                        :type="mobile?'number':''"
                        required
                        :hint="((editedItem.standard !== undefined) ? editedItem.standard : 100 ) + (editedItem.unit?editedItem.unit:'g') + ' 기준'"
                        persistent-hint />
                    </v-col>

                  </v-row>
                </v-col>

              </v-row>
              <v-row dense>

                <v-col sm="12" cols="12" class="body-2 pa-3 pt-1 pb-1">
                  <div class="font-weight-bold" style="height:25px">안내</div>
                  <div style="line-height:1.8em">
                    - 표에서 음식을 선택하고 수량 및 시간을 입력하세요.<br/>
                    - 100g, 100ml단위일 경우 180g, 180ml를 넣고 싶다면 수량을 1.8로 입력하시면 됩니다.<br/>
                    - 커스텀 음식 <v-icon color="red" small>star</v-icon><span class="caption"> (마이페이지 > 커스텀 음식에서 입력 가능합니다.)</span>
                  </div>
                </v-col>

              </v-row>
            </v-card>
          </template>

        </v-card-text>

        <v-card-actions v-if="!mobile" class="ma-4 mb-0 mt-3 pb-3 pt-0">
          <v-btn color="secondary" class="white--text" @click="close">취소</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="#FBDE44FF" class="black--text"  :disabled="!food_selected || !editedItem.amount"  @click="save2">저장 후 추가</v-btn>
          <!--<v-btn color="#FBDE44FF" class="black--text" @click="save">저장</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import {
  get_custom_food,
  post_custom_food,
} from '../../common/functions.js';


import { mapGetters } from 'vuex';

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      custom_food_title: "",
      loading: true,
      foods: [],
      search: "",
      dialog: false,
      selected: false,
      headers: [
        {
          text: '음식',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: '섭취량', value: 'amount',  align: 'right',width:80, },
        { text: '탄수화물 g', value: 'carb', align: 'right',width:100, },
        { text: '단백질 g', value: 'protein', align: 'right',width:100, },
        { text: '지방 g', value: 'fat', align: 'right',width:80, },
        { text: '', value: 'action', sortable: false, width:80, },
      ],
      rawheaders: [
        {
          text: '음식',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: '기준량', align: 'right', value: 'standard', sortable: false, width: 100, },
        { text: '탄수화물g', value: 'carb', align: 'right', sortable: false, width:100, },
        { text: '단백질g', value: 'protein', align: 'right', sortable: false, width:80, },
        { text: '지방g', value: 'fat', align: 'right', sortable: false, width:70, },
      ],
      rawfoods: [],
      isEdit: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        amount: 1,
        fat: 0,
        protein: 0,
        carb: 0,
        calory: 0,
      },
      defaultItem: {
        name: '',
        amount: '',
        fat: 0,
        protein: 0,
        carb: 0,
        calory: 0,
      },
      calc_calory:0,
      calc_carb:0,
      calc_fat:0,
      calc_protein:0,
      chart: null,
      hour_disabled: false,
      food_selected: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      data: "data",
      mobile: "mobile",
      loaded: "loaded",

    }),
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  mounted: async function() {
    if ( firebase.auth().currentUser ) {
      const id_token = await firebase.auth().currentUser.getIdToken();
      const supplement = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });
      if (supplement.data.length>0) {
        let latest_supplement = supplement.data[supplement.data.length-1];
        this.foods = latest_supplement.foodlist;
      }

      const raw_foods = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food', {
        params: { email: this.user.data.email, type: 2 },
        headers: {'id_token': id_token},
      });

      if (raw_foods.data.length>0) {
        this.rawfoods = raw_foods.data;
      }

      //// 커스텀 푸드
      const custom_food = await get_custom_food(this.user.data.email, 2);
      if (custom_food.length>0) {
        this.rawfoods.unshift(...custom_food);
      }
      ////

      this.draw_graph();
    }
  },
  methods: {
    draw_graph: async function() {

      if ( this.chart !== null )
        this.chart.destroy();

      this.calc_calory = 0;
      this.calc_carb = 0;
      this.calc_fat = 0;
      this.calc_protein = 0;

      for ( let each of this.foods ) {
        this.calc_carb += each.carb*each.amount;
        this.calc_fat += each.fat*each.amount;
        this.calc_protein += each.protein*each.amount;

        this.calc_calory += each.carb*each.amount*4 + each.protein*each.amount*4 + each.fat*each.amount*9;
      }

      let calc_total = this.calc_carb+this.calc_fat+this.calc_protein;

      this.calc_carb = Math.round(this.calc_carb*10)/10;
      this.calc_fat = Math.round(this.calc_fat*10)/10;
      this.calc_protein = Math.round(this.calc_protein*10)/10;

      const chart_ctx = document.getElementById('supplement_').getContext('2d');
      this.chart = new this.$Chart(chart_ctx, {
        type: 'bar',
        data: {
          datasets: [
            {
              label: "탄수화물",
              data: [this.calc_carb],
              fill: true,
              backgroundColor: ["#70AD47BB"],
              barPercentage: 0.4,
              borderWidth: 1,
            },
            {
              label: "단백질",
              data: [this.calc_protein],
              fill: true,
              backgroundColor: ["#4472C7BB"],
              barPercentage: 0.4,
              borderWidth: 1,
            },
            {
              label: "지방",
              data: [this.calc_fat],
              fill: true,
              backgroundColor: ["#ED7D31BB"],
              barPercentage: 0.4,
              borderWidth: 1,
            },
          ],
        },
        options: {
                animation: {
                  duration: 0,
                },
          responsive: true,
          maintainAspectRatio: false, // default value. false일 경우 포함된 div의 크기에 맞춰서 그려짐.
          legend: {
            labels: {
              //fontColor: 'white',
            }
          },
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                //fontColor: 'white',
              }
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                //fontColor: 'white',
                max: calc_total+calc_total/100*20
              },
            }]
          },
          plugins: {
            datalabels: {
              color: 'black',
              align: 'center',
              offset: 50,
              formatter: function(value, context) {
                return Number(value).format()+" g";
              }
            }
          },
        },
      });


      this.loading = false;

    },

    // selectRawfood: async function(v) {
    //   this.editedItem = v;
    //   this.editedItem.amount = 1;
    //   this.selected = true;
    // },

    selectRawFood: async function(v) {

      this.editedItem = this.$lodash.cloneDeep(v);
      this.editedItem.amount = 1;
      this.food_selected = true;

      if ( v._id === undefined ) {
        this.hour_disabled = false;
      } else {
        this.hour_disabled = true;
      }
      this.editedItem.time = 1;
    },

    editItem: function (item) {
      this.editedIndex = this.foods.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.isEdit = true;
    },

    deleteItem: async function (index) {
      //const index = this.foods.indexOf(item)
      confirm('삭제하시겠습니까?') && this.foods.splice(index, 1);
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement', {
        email: this.user.data.email,
        foodlist: this.foods,
      }, {
        headers: {'id_token': id_token},
      });

      this.draw_graph();

    },

    close: function () {
      this.dialog = false;
      this.food_selected = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    close2: function () {
      this.food_selected = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save: async function () {
      if (this.editedIndex > -1) {
        Object.assign(this.foods[this.editedIndex], this.editedItem)
      } else {
        this.foods.push(this.editedItem)
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement', {
        email: this.user.data.email,
        foodlist: this.foods,
      }, {
        headers: {'id_token': id_token},
      });

      this.isEdit = false;

      this.draw_graph();

      this.close()
    },

    save2: async function () {
      if (this.editedIndex > -1) {
        Object.assign(this.foods[this.editedIndex], this.editedItem)
      } else {
        this.foods.push(this.editedItem)
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement', {
        email: this.user.data.email,
        foodlist: this.foods,
      }, {
        headers: {'id_token': id_token},
      });

      this.isEdit = false;

      this.draw_graph();

      this.close2()
    },

    add_custom: async function () {
      if ( confirm ( "커스텀 푸드에 추가하시겠습니까?") ) {
        let food_ = {
          email: this.user.data.email,
          name: this.custom_food_title,
          type: 1,
          calory: this.calc_calory,
          carb: this.calc_carb,
          protein: this.calc_protein,
          fat: this.calc_fat,
          subfoodlist: this.foods,
        }

        await post_custom_food(this.user.data.email, food_);

        this.custom_food_title="";
      }
    }
  }
}
</script>

<style scoped>

canvas#supplement_ {
  height: 300px;
  min-height: 300px;
  max-height: 300px;
  width:100%;
  min-width: 100%;
  max-width: 100%;
  background:transparent;
}

.mobile {
  height: 200px;
  max-height: 200px;
  width:100%;
  background:transparent;
}

.op_btn {
  position:absolute;
  right:0;
  top:0;
}

.op_btn::before {
  color: transparent;
}

.op_btn::hover {
  color: transparent;
}

</style>
