<template>
  <div class="basic">
    <v-row dense v-if="!loaded">
      <v-container fluid class="ma-10">
        <v-row dense>
          <v-col cols="12">
            <v-row class="align-center justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-col cols="12" align=center>
                기본 정보를 불러오고 있습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row no-gutters v-if="loaded" align="center" justify="center" >
      <v-col cols="12">

        <v-card flat class="pa-4 ma-3">
          <v-row dense>
            <v-col cols="4" class="subtitle-2 pa-4" align="center" >
              <div class="info_title">체지방률</div>
              <div class="recommended">{{ fatrate_init }}</div>
              <div class="unit">%</div>
            </v-col>
            <v-col cols="4" class="subtitle-2  pa-4" align="center" >
              <div class="info_title">골격근량</div>
              <div class="recommended">{{ muscle_init }}</div>
              <div class="unit">kg</div>
            </v-col>
            <v-col cols="4" class="subtitle-2 big pa-4" align="center" >
              <v-text-field
                dense
                v-model.number="height_init"
                class="center-input mt-4 narrow"
                label="키"
                :type="mobile?'number':''"
                required
                hint="cm"
                persistent-hint
                v-on:change="changeHeight" />
            </v-col>

          </v-row>

        </v-card>

        <v-card flat class="pa-4 ma-3">

          <v-row dense class="mt-0">
            <v-col cols="2" class="ma-0 mb-3 pa-0">

            </v-col>
            <v-col v-if="user.sex == 'M'" cols="10" class="caption ma-0 mb-3 pa-0"  style="position:relative; background:transparent;">
              <div :style="{'position':'absolute', 'left':'0%'}">6</div>
              <div :style="{'position':'absolute', 'left':'20%'}">9.5</div>
              <div :style="{'position':'absolute', 'left':'40%'}">11.5</div>
              <div :style="{'position':'absolute', 'left':'60%'}">13.2</div>
              <div :style="{'position':'absolute', 'left':'80%'}">14.5</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'0%', 'width':'20%'}" align="center">매우낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'20%', 'width':'20%'}" align="center">낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'40%', 'width':'20%'}" align="center">보통</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'60%', 'width':'20%'}" align="center">높음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'80%', 'width':'20%'}" align="center">매우높음</div>
            </v-col>
            <v-col v-if="user.sex == 'F'" cols="10" class="caption ma-0 mb-3 pa-0"  style="position:relative; background:transparent;">
              <div :style="{'position':'absolute', 'left':'0%'}">4</div>
              <div :style="{'position':'absolute', 'left':'20%'}">7</div>
              <div :style="{'position':'absolute', 'left':'40%'}">8.6</div>
              <div :style="{'position':'absolute', 'left':'60%'}">9.8</div>
              <div :style="{'position':'absolute', 'left':'80%'}">11.5</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'0%', 'width':'20%'}" align="center">매우낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'20%', 'width':'20%'}" align="center">낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'40%', 'width':'20%'}" align="center">보통</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'60%', 'width':'20%'}" align="center">높음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'80%', 'width':'20%'}" align="center">매우높음</div>
            </v-col>
          </v-row>

          <v-row dense class="mt-1">
            <v-col cols="2" class="subtitle-2 font-weight-bold" align=center>
              골격근량
            </v-col>
            <v-col v-if="user.sex == 'M'" cols="10" class="caption pa-0" style="position:relative; background: rgb(214,57,57); background: linear-gradient(90deg, rgba(214,57,57,1) 0%, rgba(228,175,11,1) 50%, rgba(15,106,195,1) 100%);">
              <div :style="{'position':'absolute', 'left':'20%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'40%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'60%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'80%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div class="mt-0" :style="{'position':'relative', 'left':(muscle_v)+'%', 'border':'2px solid black', 'height':'100%', 'width':'2px'}"></div>
            </v-col>
            <v-col v-if="user.sex == 'F'" cols="10" class="caption pa-0" style="position:relative; background: rgb(214,57,57); background: linear-gradient(90deg, rgba(214,57,57,1) 0%, rgba(228,175,11,1) 50%, rgba(15,106,195,1) 100%);">
              <div :style="{'position':'absolute', 'left':'20%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'40%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'60%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'80%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div class="mt-0" :style="{'position':'relative', 'left':(muscle_v)+'%', 'border':'2px solid black', 'height':'100%', 'width':'2px'}"></div>
            </v-col>
          </v-row>

          <v-row dense class="mt-5">
            <v-col cols="2" class="ma-0 mb-3 pa-0">

            </v-col>
            <v-col v-if="user.sex == 'M'" cols="10" class="caption ma-0 mb-3 pa-0"  style="position:relative; background:transparent;">
              <div :style="{'position':'absolute', 'left':'0%'}">3%</div>
              <div :style="{'position':'absolute', 'left':'20%'}">8%</div>
              <div :style="{'position':'absolute', 'left':'40%'}">12%</div>
              <div :style="{'position':'absolute', 'left':'60%'}">18%</div>
              <div :style="{'position':'absolute', 'left':'80%'}">25%</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'0%', 'width':'20%'}" align="center">매우낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'20%', 'width':'20%'}" align="center">낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'40%', 'width':'20%'}" align="center">보통</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'60%', 'width':'20%'}" align="center">높음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'80%', 'width':'20%'}" align="center">매우높음</div>
            </v-col>
            <v-col v-if="user.sex == 'F'" cols="10" class="caption ma-0 mb-3 pa-0"  style="position:relative; background:transparent;">
              <div :style="{'position':'absolute', 'left':'0%'}">7%</div>
              <div :style="{'position':'absolute', 'left':'20%'}">10%</div>
              <div :style="{'position':'absolute', 'left':'40%'}">16%</div>
              <div :style="{'position':'absolute', 'left':'60%'}">24%</div>
              <div :style="{'position':'absolute', 'left':'80%'}">30%</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'0%', 'width':'20%'}" align="center">매우낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'20%', 'width':'20%'}" align="center">낮음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'40%', 'width':'20%'}" align="center">보통</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'60%', 'width':'20%'}" align="center">높음</div>
              <div class="ma-0 mt-6 pa-0 white--text" :style="{'z-index':'1', 'position':'absolute', 'left':'80%', 'width':'20%'}" align="center">매우높음</div>
            </v-col>
          </v-row>

          <v-row dense class="mt-1">
            <v-col cols="2" class="subtitle-2 font-weight-bold" align=center>
              체지방률
            </v-col>
            <v-col v-if="user.sex == 'M'" cols="10" class="caption pa-0"  style="position:relative; background: rgb(214,57,57); background: linear-gradient(270deg, rgba(214,57,57,1) 0%, rgba(228,175,11,1) 50%, rgba(15,106,195,1) 100%);">
              <div :style="{'position':'absolute', 'left':'20%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'40%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'60%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'80%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div class="mt-0" :style="{'position':'relative', 'left':(fat_v)+'%', 'border':'2px solid black', 'height':'100%', 'width':'2px'}"></div>
            </v-col>
            <v-col v-if="user.sex == 'F'" cols="10" class="caption pa-0"  style="position:relative; background: rgb(214,57,57); background: linear-gradient(270deg, rgba(214,57,57,1) 0%, rgba(228,175,11,1) 50%, rgba(15,106,195,1) 100%);">
              <div :style="{'position':'absolute', 'left':'20%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'40%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'60%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div :style="{'position':'absolute', 'left':'80%', 'border':'1px dotted white', 'height':'100%', 'width':'1px'}"></div>
              <div class="mt-0" :style="{'position':'relative', 'left':(fat_v)+'%', 'border':'2px solid black', 'height':'100%', 'width':'2px'}"></div>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-card-title>추천 체중 관리</v-card-title>
          <v-card-text class="font-weight-bold" v-if="recommanded_y>=0">
            {{ recommended_data[recommanded_y][recommanded_x] }}
          </v-card-text>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-card-title>설명</v-card-title>
          <v-card-text>
            <div class="font-weight-bold">상승다이어트</div>
            칼로리 - 사용하는 만큼 (±200)<br/>
            단백질 - 체중당 1.6~2g (초보자2g)<br/>
            운동 - 웨이트가 유산소보다 약간 더 많게<br/><br/>

            <div class="font-weight-bold">벌크업</div>
            칼로리 - 사용하는 것보다 조금 더 많이 (+200 ~ 300)<br/>
            단백질 - 체중당 1.6~2g (초보자2g)<br/>
            운동 - 웨이트 위주, 유산소는 체력과 심폐능력 향상용<br/><br/>

            <div class="font-weight-bold">다이어트</div>
            칼로리 - 사용하는 것보다 적게 (-500 ~ -700)<br/>
            단백질 - 체중당 1.2~1.6g <br/>
            운동 - 웨이트보다 유산소를 많이, 단 웨이트도 꼭 할 것<br/><br/>


            <div class="font-weight-bold">커팅 (다이어트와 비슷하나, 근손실을 고려해 천천히 진행)</div>
            칼로리 - 사용하는 것보다 적게 (-500)<br/>
            단백질 - 체중당 1.2 ~ 1.6g<br/>
            운동 - 웨이트보다 유산소를 많이, 단 웨이트도 꼭 할 것<br/><br/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex';

import '../../common/common.js';

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      muscle_v:0,
      fat_v:0,
      fatrate_init:0,
      muscle_init:0,
      height_init:0,
      value:0,
      recommanded_x: -1,
      recommanded_y: -1,
      recommended_data: [
        [
          '대회 준비가 아니라면, 오래 이 상태를 유지하지 않는 것이 좋습니다. 사람에 따라서 호르몬이 좋지 않게 유지될 수 있습니다.',
          '커팅 추천',
          '커팅 추천',
          '다이어트 추천',
          '다이어트 추천',
        ],
        [
          '대회 준비가 아니라면, 오래 이 상태를 유지하지 않는 것이 좋습니다. 사람에 따라서 호르몬이 좋지 않게 유지될 수 있습니다.',
          '커팅 추천',
          '커팅 추천',
          '다이어트 추천',
          '다이어트 추천',
        ],
        [
          '벌크업 추천',
          '벌크업, 린매스업 추천',
          '벌크업, 커팅 모두 가능. 단, 커팅 시 근육량이 낮아 근육 볼륨이 상당히 줄어들 수 있음. 상승다이어트 가능하나, 식단, 운동 노력이 상당히 필요.',
          '다이어트 추천, 상승다이어트 가능하나, 식단, 운동 노력이 상당히 필요.',
          '다이어트 추천, 상승다이어트 가능하나, 식단, 운동 노력이 상당히 필요.',
        ],
        [
          '벌크업 추천',
          '벌크업 추천',
          '상승 다이어트 추천',
          '상승 다이어트 추천',
          '상승 다이어트 추천',
        ],
        [
          '벌크업 추천',
          '벌크업 추천',
          '상승 다이어트 추천',
          '상승 다이어트 추천',
          '상승 다이어트 추천',
        ]
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      data: "data",
      mobile: "mobile",
      loaded: "loaded",

    }),
    getLoaded: function() {
      return this.$store.getters.loaded;
    },
    getMuscle: function() {
      return this.$store.getters.data.muscle;
    },
    getFatrate: function() {
      return this.$store.getters.data.fat;
    },
  },
  watch: {
    getLoaded: async function(v, oldv) {
      if ( v == true ) {
        await this.reload_info();
      }
    },
    getMuscle: function(v, oldv) {
      this.muscle_init = v;
      this.calc_value();
      this.calc_recommended();
    },
    getFatrate: function(v, oldv) {
      this.fatrate_init = v;
      this.calc_value();
      this.calc_recommended();
    },
  },
  mounted: async function() {
    if ( this.loaded ) {
      await this.reload_info();
    }
  },
  methods: {
    reload_info: async function() {
      if ( firebase.auth().currentUser ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        const user = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/one', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        this.height_init = user.data.height;

        this.muscle_init = this.data.muscle;
        this.fatrate_init = this.data.fat;

        await this.calc_value();
        await this.calc_recommended();

      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }
    },
    changeHeight: async function() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/height/', {
        email: this.user.data.email,
        height: this.height_init,
      }, {
        headers: {'id_token': id_token},
      });

      await this.calc_value();
      await this.calc_recommended();
    },

    calc_value: async function() {
      let height_ = (this.height_init/100);
      this.value = Math.round((this.muscle_init/(height_*height_))*10)/10;
    },

    calc_recommended: async function() {

      if ( this.user.sex == 'M' ) {
        if ( this.value >= 14.5 ) {
          this.recommanded_y = 0;
          this.muscle_v = 80+( (this.value-14.5)/(18-14.5) * 100)/100*20;
        } else if ( this.value >= 13.2 ) {
          this.recommanded_y = 1;
          this.muscle_v = 60+( (this.value-13.2)/(14.5-13.2) * 100)/100*20;
        } else if ( this.value >= 11.5 ) {
          this.recommanded_y = 2;
          this.muscle_v = 40+( (this.value-11.5)/(13.2-11.5) * 100)/100*20;
        } else if ( this.value >= 9.5 ) {
          this.recommanded_y = 3;
          this.muscle_v = 20+( (this.value-9.5)/(11.5-9.5) * 100)/100*20;
        } else if ( this.value >= 6 ) {
          this.recommanded_y = 4;
          this.muscle_v = 0+( (this.value-6)/(9.5-6) * 100)/100*20;
        }

        if ( this.fatrate_init >= 25 ) {
          this.recommanded_x = 4;
          this.fat_v = 80+( (this.fatrate_init-25)/(35-25) * 100)/100*20;
        } else if ( this.fatrate_init >= 18 ) {
          this.recommanded_x = 3;
          this.fat_v = 60+( (this.fatrate_init-18)/(25-18) * 100)/100*20;
        } else if ( this.fatrate_init >= 12 ) {
          this.recommanded_x = 2;
          this.fat_v = 40+( (this.fatrate_init-12)/(18-12) * 100)/100*20;
        } else if ( this.fatrate_init >= 8 ) {
          this.recommanded_x = 1;
          this.fat_v = 20+( (this.fatrate_init-8)/(12-8) * 100)/100*20;
        } else if ( this.fatrate_init >= 3 ) {
          this.recommanded_x = 0;
          this.fat_v = 0+( (this.fatrate_init-3)/(8-3) * 100)/100*20;
        }
      } else {
        if ( this.value >= 11.5 ) {
          this.recommanded_y = 0;
          this.muscle_v = 80+( (this.value-11.5)/(15-11.5) * 100)/100*20;
        } else if ( this.value >= 9.8 ) {
          this.recommanded_y = 1;
          this.muscle_v = 60+( (this.value-9.8)/(11.5-9.8) * 100)/100*20;
        } else if ( this.value >= 8.6 ) {
          this.recommanded_y = 2;
          this.muscle_v = 40+( (this.value-8.6)/(9.8-8.6) * 100)/100*20;
        } else if ( this.value >= 7 ) {
          this.recommanded_y = 3;
          this.muscle_v = 20+( (this.value-7)/(8.6-7) * 100)/100*20;
        } else if ( this.value >= 4 ) {
          this.recommanded_y = 4;
          this.muscle_v = 0+( (this.value-4)/(7-4) * 100)/100*20;
        }

        if ( this.fatrate_init >= 30 ) {
          this.recommanded_x = 4;
          this.fat_v = 80+( (this.fatrate_init-30)/(40-30) * 100)/100*20;
        } else if ( this.fatrate_init >= 24 ) {
          this.recommanded_x = 3;
          this.fat_v = 60+( (this.fatrate_init-24)/(30-24) * 100)/100*20;
        } else if ( this.fatrate_init >= 16 ) {
          this.recommanded_x = 2;
          this.fat_v = 40+( (this.fatrate_init-16)/(24-16) * 100)/100*20;
        } else if ( this.fatrate_init >= 10 ) {
          this.recommanded_x = 1;
          this.fat_v = 20+( (this.fatrate_init-10)/(16-10) * 100)/100*20;
        } else if ( this.fatrate_init >= 7 ) {
          this.recommanded_x = 0;
          this.fat_v = 0+( (this.fatrate_init-7)/(10-7) * 100)/100*20;
        }
      }
    }

  }
}
</script>

<style scoped>

.v-data-table td {
  padding:0 !important;
  padding-right: 10px !important;
  min-height:15px !important;
  height: 25px !important;
}

</style>
