<template>
  <div class="basic">
    <v-row dense v-if="!loaded">
      <v-container fluid class="ma-10">
        <v-row dense>
          <v-col cols="12">
            <v-row class="align-center justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-col cols="12" align=center>
                기본 정보를 불러오고 있습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row dense v-if="loaded" align="center" justify="center" >
      <v-col cols="12">

        <v-card flat class="subtitle-2 pa-4 ma-3">
          설문을 선택하면, 자신에 가장 잘 맞는 단백질 보충제를 추천합니다.
        </v-card>

        <v-card flat class="body-2 pa-4 ma-3">
          <div class="subtitle-2">1번항 : 우유 한번에 500ml 마시면 배아픔</div>
          <v-radio-group v-model="q1" v-on:change="q_change" class="ma-0 pa-0">
            <v-row dense>
              <v-col cols="3">
                <v-radio label="Y" :value="1" class="radio" />
              </v-col>
              <v-col cols="3">
                <v-radio label="N" :value="2" class="radio" />
              </v-col>
              <v-col cols="3">
                <v-radio label="soso" :value="3" class="radio" />
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card>
        <v-card flat class="body-2 pa-4 ma-3">
          <div class="subtitle-2">2번항 : 트러블이 덜한것(단, 맛이 없음)</div>
          <v-radio-group v-model="q2" v-on:change="q_change" class="ma-0 pa-0">
            <v-row dense>
              <v-col cols="3">
                <v-radio label="Y" :value="1" class="radio" />
              </v-col>
              <v-col cols="3">
                <v-radio label="상관없음" :value="2" class="radio" />
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card>
        <v-card flat class="body-2 pa-4 ma-3">
          <div class="subtitle-2">3번항 : 맛(맛있을수록 첨가물이 많음)</div>
          <v-radio-group v-model="q3" v-on:change="q_change" class="ma-0 pa-0">
            <v-row dense>
              <v-col cols="3">
                <v-radio label="보통이면됨" :value="1" class="radio" />
              </v-col>
              <v-col cols="3">
                <v-radio label="맛있어야함" :value="2" class="radio" />
              </v-col>
              <v-col cols="3">
                <v-radio label="상관없음" :value="3" class="radio" />
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card>
        <v-card flat class="body-2 pa-4 ma-3">
          <div class="subtitle-2">4번항 : 천연감미료(스테비아)</div>
          <v-radio-group v-model="q4" v-on:change="q_change" class="ma-0 pa-0">
            <v-row dense>
              <v-col cols="3">
                <v-radio label="Y" :value="1" class="radio" />
              </v-col>
              <v-col cols="3">
                <v-radio label="N" :value="2" class="radio" />
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card>
        <v-card flat class="body-2 pa-4 ma-3">
          <div class="subtitle-2">5번항 : 운동 후 식사 30분 이내 가능(나눠먹기로 커버가능)</div>
          <v-radio-group v-model="q5" v-on:change="q_change" class="ma-0 pa-0">
            <v-row dense>
              <v-col cols="3">
                <v-radio label="Y" :value="1" class="radio" />
              </v-col>
              <v-col cols="3">
                <v-radio label="N" :value="2" class="radio" />
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card>

        <v-card flat class="pa-4 ma-3 black--text" color="#FBDE44AA" style="min-height:100px;">
          <div class="mb-2 font-weight-bold title">권장 단백질 보충제</div>

          <v-row dense v-for="each of affected_list" :key="each.item" class="ma-2 mb-3 body-2">
            <v-col cols="6" class="subtitle-2 font-weight-bold">{{ each.name}}</v-col>
            <v-col cols="3" v-if="each.note">{{ each.note }}</v-col>
            <v-col cols="3" v-if="each.matches">{{ each.matches }} 항목 일치</v-col>
            <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0" v-if="each.comment"> {{ each.comment }} </v-col>
            <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0" v-if="each.extra_note"> {{ each.extra_note }} </v-col>
          </v-row>
        </v-card>

      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex';

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      list: [],
      date: "",
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
      q5: 0,
      affected_list: [],
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      limited: "limited",
      data: "data",
      loaded: "loaded",

    }),
    getLoaded: function() {
      return this.$store.getters.loaded;
    },
  },
  watch: {
    getLoaded: async function(v, oldv) {
      if ( v == true ) {
        await this.reload_info(true);
      }
    },
  },
  mounted: async function() {
    if ( this.loaded ) {
      this.reload_info(true);
    }
  },
  methods: {
    reload_info: async function(reloadall) {
      const id_token = await firebase.auth().currentUser.getIdToken();
      const supps_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement/choice/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      if (supps_.data.length>0) {
        this.list = supps_.data;
      }
    },
    q_change: async function() {
      if ( this.q1 != 0
        && this.q2 != 0
        && this.q3 != 0
        && this.q4 != 0
        && this.q5 != 0) {

        let q1_l = [];
        let q2_l = [];
        let q3_l = [];
        let q4_l = [];
        let q5_l = [];

        if ( this.q1 == 2 ) {
          q1_l = [1,2,3]
        } else if ( this.q1 == 3 ) {
          q1_l = [1,3]
        } else {
          q1_l = [1]
        }

        if ( this.q2 == 2 ) {
          q2_l = [1,2]
        } else {
          q2_l = [1]
        }

        if ( this.q3 == 3 ) {
          q3_l = [1,2,3]
        } else if ( this.q3 == 1 ) {
          q3_l = [1,2]
        } else {
          q3_l = [1]
        }

        if ( this.q4 == 2 ) {
          q4_l = [2]
        } else {
          q4_l = [1]
        }

        if ( this.q5 == 2 ) {
          q5_l = [1,2]
        } else {
          q5_l = [1]
        }

        this.affected_list = [];

        for ( let each of this.list ) {
          if ( q1_l.includes(each.q1) &&
               q2_l.includes(each.q2) &&
               q3_l.includes(each.q3) &&
               q4_l.includes(each.q4) &&
               q5_l.includes(each.q5) ) {

            let t_ = this.$lodash.cloneDeep(each);

            t_.matches = 0;

            if ( each.q1 == this.q1 ) {
              t_.matches++;
            }
            if ( each.q2 == this.q2 ) {
              t_.matches++;
            }
            if ( each.q3 == this.q3 ) {
              t_.matches++;
            }
            if ( each.q4 == this.q4 ) {
              t_.matches++;
            }
            if ( each.q5 == this.q5 ) {
              t_.matches++;
            }

            if ( each.q5 == 1 && this.q5 == 2) {
              t_.extra_note = "운동후 3/4바로섭취, 30분후 1/4섭취 방법 추천";
            }

            this.affected_list.push(t_);

            console.log(t_.matches);
          }
        }
      }
    },
  }
}
</script>

<style scoped>

.v-radio >>> label {
  font-size:14px !important;
}


</style>
