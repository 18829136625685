<template>
  <div>
    <div v-if="!userloading && user.login">
      <v-tabs 
        v-model="tab" 
        :vertical="!mobile"  
        show-arrows 
        class="fixed"  background-color="#141E30" color="white" style="min-height:calc(100vh - 48px)">
        <v-tab key="diet" to="#diet">다이어트</v-tab>
        <v-tab key="maintain" to="#maintain">유지어트</v-tab>
        <!--<v-tab key="cheating" to="#cheating">치팅도우미</v-tab>-->
        <v-tab key="supplement" to="#supplement">보충제활용</v-tab>
        <!-- <v-tab key="supplement_choice" to="#supplement_choice">보충제선택</v-tab> -->
        <v-tab key="loading" to="#loading">벤딩 로딩</v-tab>
        <!-- <v-tab key="muscle" to="#muscle">골격근/체지방</v-tab> -->
        <v-tab key="cycling" to="#cycling" v-if="user.sex == 'M'">탄수화물<br/>사이클링</v-tab>

        <v-tabs-items touchless v-model="tab">
          <v-tab-item
            id="diet"
            key="diet"
            :transition="false"
            :reverse-transition="false">
            <Diet />
          </v-tab-item>

          <v-tab-item
            id="maintain"
            key="maintain"
            :transition="false"
            :reverse-transition="false">
            <Maintenance />
          </v-tab-item>
  <!--
          <v-tab-item
            id="cheating"
            key="cheating"
            :transition="false"
            :reverse-transition="false">
            <Cheating />
          </v-tab-item>
  -->
          <v-tab-item
            id="supplement"
            key="supplement"
            :transition="false"
            :reverse-transition="false">
            <Supplement />
          </v-tab-item>
<!-- 
          <v-tab-item
            id="supplement_choice"
            key="supplement_choice"
            :transition="false"
            :reverse-transition="false">
            <SupplementChoice />
          </v-tab-item> -->

          <v-tab-item
            id="loading"
            key="loading"
            :transition="false"
            :reverse-transition="false">
            <Loading />
          </v-tab-item>
<!-- 
          <v-tab-item
            id="muscle"
            key="muscle"
            :transition="false"
            :reverse-transition="false">
            <Muscle />
          </v-tab-item> -->

          <v-tab-item
            v-if="user.sex == 'M'"
            id="cycling"
            key="cycling"
            :transition="false"
            :reverse-transition="false">
            <Cycling />
          </v-tab-item>
        </v-tabs-items>

      </v-tabs>

    </div>
    <div v-if="!userloading && !user.login" >
      <NeedLogin />
    </div>
    <div v-if="userloading">
      <UserLoading />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { mapGetters } from 'vuex'

import NeedLogin from './User/NeedLogin';
import UserLoading from './User/UserLoading';
import Diet from './Weight/Diet';
import Maintenance from './Weight/Maintenance';
import Supplement from './Main/Supplement';
import SupplementChoice from './Weight/SupplementChoice';
import Loading from './Weight/Loading';
import Cycling from './Weight/Cycling';
import Muscle from './Weight/Muscle';
//import Cheating from './Main/Cheating';

export default {
  // components: {
  //   Graph,
  // },
  components: {
    NeedLogin,
    Diet,
    Maintenance,
    UserLoading,
    Supplement,
    // SupplementChoice,
    Loading,
    Cycling,
    // Muscle,
    //Cheating,
  },
  props: {
    msg: String
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      userloading: "userloading",
    })
  },
  data() {
    return {
      tab: "diet",
    }
  },
}
</script>

<style scoped>

.theme--light.v-tabs-items {
  background-color: transparent !important;
}

</style>
