<template>
  <div class="basic">
    <v-row dense v-if="!loaded">
      <v-container fluid class="ma-10">
        <v-row dense>
          <v-col cols="12">
            <v-row class="align-center justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-col cols="12" align=center>
                기본 정보를 불러오고 있습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-card flat class="body-2 pa-4 ma-3" style="border-radius:10px; border:4px solid #dddddd;">
      <div class="font-weight-bold">안내</div>
      <div>줄어든 대사량을 천천히 늘리는 방법</div>
      <div>운동을 좋아하고 활력이 있다면 복구 속도는 빠르게</div>
      <div>평소 무기력하고 활동량이 적었다면 복구 속도는 천천히</div>
    </v-card>

    <v-row no-gutters v-if="loaded"  align="center" justify="center" >
      <v-col cols="12">

        <v-card flat class="pa-4 ma-3">
          <v-row>
            <v-col cols="4" class="subtitle-2 big pa-4">
              <v-radio-group v-model="mode" v-on:change="mode_change" :disabled="dietstatus==2">
                <v-radio label="일반" :value="1" class="radio" />
                <v-radio label="간단" :value="0" class="radio" />
              </v-radio-group>
            </v-col>
            <v-col cols="8" class="caption big pa-4" v-if="!mode">
              <div>
                - 관련 정보를 마지막 다이어트 DB에서 가지고 옵니다.<br/>
                - 정보를 맞춰서 넣어주세요.
              </div>
            </v-col>
            <v-col cols="4" class="subtitle-2 big pa-4" v-if="!mode">
              <v-text-field
                 :disabled="dietstatus==2"
                dense
                v-model.number="diet_period"
                class="center-input mt-5"
                label="다이어트 기간"
                :type="mobile?'number':''"
                required
                hint="일"
                persistent-hint
                v-on:change="changeDietDay" />
            </v-col>
            <v-col cols="4" class="subtitle-2 big pa-4" v-if="!mode">
              <v-text-field
               :disabled="dietstatus==2"
                dense
                v-model.number="calory_delta_init"
                class="center-input mt-5"
                label="다이어트 시작시 줄인 칼로리"
                :type="mobile?'number':''"
                required
                hint="Kcal"
                persistent-hint
                v-on:change="changeCDF" />
            </v-col>
            <v-col cols="4" class="subtitle-2 big pa-4" v-if="!mode">
              <v-text-field
               :disabled="dietstatus==2"
                dense
                v-model.number="calory_delta_weekly"
                class="center-input mt-5"
                label="매주 줄인 칼로리"
                :type="mobile?'number':''"
                required
                hint="Kcal"
                persistent-hint
                v-on:change="changeCDD" />
            </v-col>
            <v-col md="8" cols="8" class="subtitle-2 big pa-4" v-if="mode">
              <v-text-field
               :disabled="dietstatus==2"
                dense
                v-model.number="calory_diff_simple"
                class="center-input mt-5"
                label="활동칼로리 - 현섭취칼로리"
                :type="mobile?'number':''"
                required
                hint="Kcal"
                persistent-hint />
            </v-col>
            <v-col md="12" cols="12" class="subtitle-2 big pa-4">
              <v-select
               :disabled="dietstatus==2"
                :items="speed"
                item-text="name"
                item-value="speed"
                v-model.number="selected_speed"
                class="right-input mt-2"
                style={{}}
                label="복구 속도"
                v-on:change="changeSpeed" />
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-row>
            <v-col cols="4" class="subtitle-2 pa-4" align="center" >
              <div class="info_title">총 줄인 칼로리</div>
              <div class="recommended">{{ total_minus_kcal.format() }}</div>
              <div class="unit">Kcal</div>
            </v-col>
            <v-col cols="4" class="subtitle-2 pa-4" align="center" >
              <div class="info_title">유지 시작 칼로리</div>
              <div class="recommended">{{ starting_kcal.format() }}</div>
              <div class="unit">Kcal, 활동-섭취 칼로리에 따라 변경됩니다.</div>
            </v-col>
            <v-col cols="4" class="subtitle-2 pa-4" align="center" >
              <div class="info_title">매주 추가 칼로리</div>
              <div class="recommended">{{ calory_delta_weekly_plus }}</div>
              <div class="unit">Kcal</div>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-0 ma-3" style="background-color:transparent">
          <v-row no-gutter dense>
            <v-col cols='8'>
              <v-card flat class="pa-3 ma-0 black--text"  color="#FBDE44AA"  style="border:3px solid #FBDE44" >
                <div class="info_title">유지 기간 {{day_maintenance_msg}} </div>
                <div class="recommended" align="center">{{ day_maintenance }}</div>
                <div class="unit">주</div>
              </v-card>
            </v-col>
            <v-col cols='4'>
              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    color="#FBDE44FF"
                    block
                    :disabled="date != '' || dietstatus != 0"
                    @click="save">
                    시작
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    block
                    :disabled="date == ''"
                    @click="finish">
                    종료
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="12" cols="12" class="subtitle-2" align="right">
                  시작 날짜 : {{ date }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-0 ma-3" style="background-color:transparent">
          <v-row dense>

            <v-col md="4" cols="12" v-for="(each, i) in kcal_data" :key="i">
              <v-card outlined :dark="each.week == weeks" class="elevation-0 pa-3 pt-1 pb-1 ma-0">
                <v-row dense>
                  <v-col cols="6" class="subtitle-2" align="left">
                    {{ each.week }} 주차
                  </v-col>
                  <v-col cols="6" class="subtitle-2" align="right">{{ each.calory }}Kcal</v-col>

                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>

      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex';

import '../../common/common.js';

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      //
      mode: 1,
      calory_diff_simple: 0,
      // diet
      show_desc: false,
      calory_delta: 0,
      calory_delta_init: 0,
      calory_delta_weekly: 0,
      calory_delta_weekly_plus: 0,
      total_minus_kcal: 0,
      day_maintenance: 0,
      day_maintenance_msg: "",
      starting_kcal: 0,
      diet_period: 0,
      speed: [
        { name: '천천히', speed: 2 },
        { name: '일반', speed: 3 },
        { name: '빠르게', speed: 4 },
      ],
      selected_speed: 2,
      date: "",
      weeks: 1,
      kcal_data: [],
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      data: "data",
      mobile: "mobile",
      loaded: "loaded",
      dietstatus: "dietstatus",
      tdee: "tdee",

    }),
    getLoaded: function() {
      return this.$store.getters.loaded;
    },
    getCaloryCalculated: function() {
      return this.$store.getters.tdee;
    }
  },
  watch: {
    calory_diff_simple: async function() {
      await this.reload_calc();
    },
    diet_period: async function() {
      await this.reload_calc2();
    },
    calory_delta_init: async function() {
      await this.reload_calc2();
    },
    calory_delta_weekly: async function() {
      await this.reload_calc2();
    },
    getLoaded: async function(v, oldv) {
      if ( v == true ) {
        await this.reload_init();
      }
    },
    getCaloryCalculated: function(v, oldv) {
      this.reload_init();
    }
  },
  mounted: async function() {
    if ( this.loaded ) {
      await this.reload_init();
    }
  },
  methods: {
    reload_init: async function() {
      if ( firebase.auth().currentUser ) {
        await this.get_diet();
        await this.get_info();
        await this.reload_fatrate();
      }
    },
    get_info: async function() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      const data_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/maintain/latest', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      //this.$store.dispatch('fetchDietData', diet_.data);

      const set_init = () => {
        this.diet_period = 0;
        this.day_maintenance = 0;
        this.calory_delta_init = 0;
        this.calory_delta_weekly = 0;
        this.selected_speed = 2;
        this.mode = 1;
        this.calory_diff_simple = 0;
        this.calory_calculated = this.tdee;
      }

      if ( data_.data.length > 0 ) {
        // already data
        let latest_ = data_.data[data_.data.length-1];

        if ( latest_.date_f != "") {
          set_init();
        } else {
          this.mode = latest_.mode;
          this.calory_diff_simple = latest_.calory_diff_simple;
          this.diet_period = latest_.diet_period;
          this.calory_delta_init = latest_.calory_delta_init;
          this.calory_delta_weekly = latest_.calory_delta_weekly;
          this.selected_speed = latest_.speed;
          this.calory_calculated = latest_.calculated_calory;
          this.date = latest_.date;
        }
      } else {
        // nothing init
        set_init();
      }

      this.weeks = 1;
      if ( this.date != "")
        this.weeks = this.weeks + (this.$moment().diff(this.$moment(this.date, "YYYY-MM-DD"), 'weeks'));

    },

    reload_calc: async function() {

      let selected_speed_ = 7;
      this.total_minus_kcal = this.calory_diff_simple;

      if ( this.calory_diff_simple < 700 ) {

        // (2) 700칼로리 미만시 (N/B)
        // 1주차 섭취칼로리 + (N/B)
        // 2주차 섭취칼로리 + (N/B)*2
        // 3주차 섭취칼로리 + (N/B)*3
        // 4주차 섭취칼로리 + (N/B)*4

        switch(this.selected_speed) {
        case 2:
          selected_speed_ = 8;
          this.day_maintenance = 8;
          break;
        case 3:
          selected_speed_ = 6;
          this.day_maintenance = 6;
          break;
        case 4:
          selected_speed_ = 4;
          this.day_maintenance = 4;
          break;
        }

        // (N/B)
        this.calory_delta_weekly_plus = Math.round(((this.calory_diff_simple)/selected_speed_)/10)*10;
        this.starting_kcal = Math.round(( (this.tdee - this.calory_diff_simple +  this.calory_delta_weekly_plus) )/10)*10;

      } else {

        // (1) 700칼로리 이상시 ((N-500) /A)
        // 1주차 섭취칼로리 +500
        // 2주차 섭취칼로리 +500 + ((N-500) /A)
        // 3주차 섭취칼로리 +500 + (((N-500) /A)*2)
        // 4주차 섭취칼로리 +500 + (((N-500) /A)*3)

        switch(this.selected_speed) {
        case 2:
          selected_speed_ = 7;
          this.day_maintenance = 8;
          break;
        case 3:
          selected_speed_ = 5;
          this.day_maintenance = 6;
          break;
        case 4:
          selected_speed_ = 3;
          this.day_maintenance = 4;
          break;
        }

        // 500 + (( N-500) /A)
        this.calory_delta_weekly_plus = Math.round(((this.calory_diff_simple-500)/selected_speed_)/10)*10;
        this.starting_kcal = Math.round(( (this.tdee + 500 - this.calory_diff_simple) )/10)*10;

      }

    },

    reload_calc2: async function() {

      this.total_minus_kcal = Math.round(( (this.diet_period/7*this.calory_delta_weekly) + Number(this.calory_delta_init) )/10)*10;
      this.calory_delta_weekly_plus = Number(Number(this.selected_speed*this.calory_delta_weekly).toFixed(1));
      this.day_maintenance = Number(Number((this.total_minus_kcal - this.calory_delta_init)/this.calory_delta_weekly_plus).toFixed(1));
      this.starting_kcal = Math.round(( this.tdee - this.total_minus_kcal + 500  )/10)*10;

      console.log(this.starting_kcal);
    },

    get_diet: async function() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      const diet_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/diet/latest', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      if ( diet_.data.length > 0 ) {
        // already data
        let latest_diet = diet_.data[diet_.data.length-1];

        let anchor_day = this.$moment();
        if ( latest_diet.date_f != null && latest_diet.date_f !== "") {
          anchor_day = this.$moment(latest_diet.date_f, "YYYY-MM-DD");
        }

        let d = this.$moment(latest_diet.date, "YYYY-MM-DD");

        this.diet_period = anchor_day.diff(d, 'days');
        this.calory_delta_init = latest_diet.calory_delta_init;
        this.calory_delta_weekly = latest_diet.calory_delta_weekly;
      }

    },

    reload_fatrate: async function() {

      if ( this.dietstatus != 2 ) return;

      if ( this.mode == 1 ) {

        // 일반
        this.reload_calc();

        // (2) 700칼로리 미만시 (N/B)
        // 1주차 섭취칼로리 + (N/B)
        // 2주차 섭취칼로리 + (N/B)*2
        // 3주차 섭취칼로리 + (N/B)*3
        // 4주차 섭취칼로리 + (N/B)*4

        // (1) 700칼로리 이상시 ((N-500) /A)
        // 1주차 섭취칼로리 +500
        // 2주차 섭취칼로리 +500 + ((N-500) /A)
        // 3주차 섭취칼로리 +500 + (((N-500) /A)*2)
        // 4주차 섭취칼로리 +500 + (((N-500) /A)*3)

        if ( isNaN(this.day_maintenance) ) {
          this.day_maintenance_msg = "필요한 정보를 입력하세요.";
          this.day_maintenance = 0;
        } else {
          this.day_maintenance_msg = "";
          this.kcal_data = [];
          let i = 0;
          while ( i < this.day_maintenance ) {

            if ( this.calory_diff_simple < 700 ) {

              this.kcal_data.push({
                week: i+1,
                calory: (Math.round((this.starting_kcal+i*this.calory_delta_weekly_plus)/10)*10).format(),
                carb: 0,
                protein: 0,
                fat: 0,
              });

            } else {

              this.kcal_data.push({
                week: i+1,
                calory: (Math.round((this.starting_kcal+i*this.calory_delta_weekly_plus)/10)*10).format(),
                carb: 0,
                protein: 0,
                fat: 0,
              });

            }

            i++;

          }
        }

      } else {

        // 간단
        this.reload_calc2();

        if ( isNaN(this.day_maintenance) ) {
          this.day_maintenance_msg = "필요한 정보를 입력하세요.";
          this.day_maintenance = 0;
        } else {
          this.day_maintenance_msg = "";
          this.kcal_data = [];
          let i = 0;
          while ( i <= this.day_maintenance ) {
            this.kcal_data.push({
              week: i+1,
              calory: (Math.round((this.starting_kcal+i*this.calory_delta_weekly_plus)/10)*10).format(),
              carb: 0,
              protein: 0,
              fat: 0,
            });
            i++;
          }
        }

      }


    },

    mode_change: async function() {
      await this.reload_fatrate();
    },
    changeDietDay: async function(v) {
      //await this.reload_fatrate();
    },
    changeCDF: async function(v) {
      //await this.reload_fatrate();
    },
    changeCDD: async function(v) {
      //await this.reload_fatrate();
    },
    changeSpeed: async function(v) {
      if ( this.mode == 1 ) {
        await this.reload_calc();
      } else {
        await this.reload_calc2();
      }
      //await this.reload_fatrate();
    },

    save: async function(v) {
      if ( this.date != "" ) {
        alert("이미 시작된 유지어트가 있습니다.");
        return;
      }
      if ( this.dietstatus == 1 ) {
        alert("다이어트 중입니다. 다이어트를 종료하고 시작하세요");
        return;
      }
      if ( this.dietstatus == 3 ) {
        alert("치팅데이 중입니다. 치팅데이를 종료하고 시작하세요");
        return;
      }
      /*
      if ( this.calory_diff_simple > 700 ) {
        alert('활동칼로리-현칼로리는 700을 넘을 수 없습니다.');
        return;
      }
      */

      await this.reload_fatrate();
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/maintain', {
        email: this.user.data.email,
        mode: this.mode,
        calory_diff_simple: this.calory_diff_simple,
        diet_period: this.diet_period,
        calory_delta_init: this.calory_delta_init,
        calory_delta_weekly: this.calory_delta_weekly,
        speed: this.selected_speed,
        calculated_calory: this.starting_kcal,
      }, {
        headers: {'id_token': id_token},
      });

      this.date = this.$moment().format("YYYY-MM-DD");

      this.$store.dispatch("fetchDietStatus", 2);

      await this.reload_fatrate();
    },
    finish: async function(v) {
      if ( this.date == "") {
        alert("시작된 유지어트가 없습니다.");
        return;
      }
      if ( !confirm("진행중인 유지어트가 있습니다. 종료하겠습니까?") ) return;
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/maintain/finish/', {
        email: this.user.data.email,
        date: this.date,
      }, {
        headers: {'id_token': id_token},
      });
      this.diet_period = 0;
      this.calory_delta_init = 0;
      this.calory_delta_weekly = 0;
      this.day_maintenance = 0;
      this.mode = 1;
      this.calory_diff_simple = 0;
      this.date = "";
      this.kcal_data = [];
      this.total_minus_kcal = 0;
      this.starting_kcal = 0;
      this.calory_delta_weekly_plus = 0;

      this.$store.dispatch("fetchDietStatus", 0);

      await this.reload_fatrate();
    }
  }
}
</script>

<style scoped>

canvas {
  max-height: 200px;
  min-height: 200px;
  height: 200px;
  width:100%;

}

.radio >>> label {
  font-size: 14px;
  font-weight: bold;
}

</style>
