<template>
  <div class="basic">
    <v-row dense v-if="!loaded">
      <v-container fluid class="ma-10">
        <v-row dense>
          <v-col cols="12">
            <v-row class="align-center justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-col cols="12" align=center>
                기본 정보를 불러오고 있습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row no-gutters v-if="loaded" align="center" justify="center" >
      <v-col cols="12">

        <v-card flat class="pa-4 ma-3">
          <v-row dense>
            <v-col cols="6" class="subtitle-2 pa-0" align="center" >
              <div class="info_title">체중</div>
              <div class="recommended">{{ weight_init }}</div>
              <div class="unit">kg</div>
            </v-col>
            <v-col cols="6" class="subtitle-2 pa-0" align="center" >
              <div class="info_title">체지방률</div>
              <div class="recommended">{{ fatrate_init }}</div>
              <div class="unit">%</div>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-row dense>
            <v-col cols="4" class="subtitle-2 big3 pa-4">
              <v-text-field
                dense
                v-model.number="fatrate_want"
                class="center-input mt-4"
                label="원하는 체지방률"
                :type="mobile?'number':''"
                required
                hint="%"
                persistent-hint
                v-on:change="changeFatrateWanted" />
            </v-col>

            <v-col cols="4" class="subtitle-2 big3  pa-4">
              <v-text-field
                dense
                v-model.number="calory_delta"
                class="center-input mt-4"
                label="시작시 줄일 칼로리"
                :type="mobile?'number':''"
                required
                hint="Kcal"
                persistent-hint
                v-on:change="changeCaloryDelta">
                <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                  <template v-slot:activator="{ on }">
                    <v-icon color="grey" class="load" v-on="on">mdi-comment-question-outline</v-icon>
                  </template>
                  <span class="caption">
                    <div>다이어트시 줄일 칼로리의 적정값은 500입니다.</div>
                    <div>체격이 크거나 활동량이 많으신 분들은 700까지 올려도 되나, </div>
                    <div>여성분들이나 체격이 작으신 분들은, 혹은 다이어트를 길게 가져가실 분들은 500이 좋습니다.</div>
                  </span>
                </v-tooltip>
              </v-text-field>
            </v-col>

            <v-col cols="4" class="subtitle-2 big3  pa-4">
              <v-text-field
                dense
                v-model.number="weekly_calory_delta"
                class="center-input mt-4"
                label="매주 줄일 칼로리"
                :type="mobile?'number':''"
                required
                hint="Kcal"
                persistent-hint
                v-on:change="changeWeeklyCaloryDelta">
                <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip">
                  <template v-slot:activator="{ on }">
                    <v-icon color="grey" class="load" v-on="on">mdi-comment-question-outline</v-icon>
                  </template>
                  <span class="caption">
                    <div>다이어트가 지속됨에 따라 사용되는 에너지가 줄어들기 때문에</div>
                    <div>매주 50~100칼로리를 줄여줍니다. </div>
                    <div>여성분들, 체격이 작으신분들이나 다이어트를 길게 가져가시는 분들은 50을 넣는것을 권장해드리며,</div>
                    <div>다이어트가 지속되면 꼭 유지하는 기간을 가져주길 바랍니다.</div>
                  </span>
                </v-tooltip>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-row dense>
            <v-col md="3" cols="6" class="subtitle-2  pa-4" align="center" >
              <div class="info_title">원하는 체지방률일 때의 체중</div>
              <div class="recommended">{{ weight_target }}</div>
              <div class="unit">kg</div>
            </v-col>
            <v-col md="3" cols="6" class="subtitle-2  pa-4" align="center" >
              <div class="info_title">빼야 할 체중</div>
              <div class="recommended">{{ weight_delta }}</div>
              <div class="unit">kg</div>
            </v-col>
            <v-col md="3" cols="6">
              <v-card flat class="pa-3 pb-8 subtitle-2 black--text"  color="#FBDE44AA"  style="border:3px solid #FBDE44" >
                <div class="info_title">다이어트 기간</div>
                <div class="recommended" align="right">{{ day_diet }}</div>
                <div class="unit">일</div>
              </v-card>
            </v-col>
            <v-col md="3" cols="6">
              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    color="#FBDE44FF"
                    block
                    :disabled="date != ''"
                    @click="save">
                    시작
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    block
                    :disabled="date == '' && dietstatus != 1"
                    @click="finish">
                    종료
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    color="#FBDE44FF"
                    block
                    :disabled="date == ''"
                    @click="cheat">
                    치팅데이 추가
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-row dense>
            <v-col md="12" cols="12" align="right">
              <v-row dense>
                <v-col cols="12" align="left">
                  치팅 데이
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="4" cols="12" v-for="(each, i) in cheat_data" :key="i">
                  <v-card outlined class="elevation-0 pa-3 pt-1 pb-1 ma-3 mt-0 mb-0">
                    <v-row dense>
                      <v-col cols="6" class="subtitle-2" align="left">
                        {{ each.date }}
                      </v-col>
                      <v-col cols="5" class="subtitle-2" align="right">{{ each.total.format() }} Kcal</v-col>
                      <v-col cols="1" class="subtitle-2" align="right">
                        <v-btn xSmall color="#FBDE44FF" class="elevation-0 op_btn" @click="del_cheat(each._id, i)" >
                          <v-icon color="black" small>close</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #70AD47">{{ Math.round(each.carb).format() }} kcal</v-col>
                      <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #4472C7">{{ Math.round(each.protein).format() }} kcal</v-col>
                      <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #ED7D31">{{ Math.round(each.fat).format() }} kcal</v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="body-2 pa-4 ma-3" style="border-radius:10px; border:4px solid #dddddd;">
          <div>치팅을 추가하면 다이어트 한주가 늘어납니다. </div>
          <div>이보 전진을 위한 일보 후퇴라고 생각하시면 좋습니다. </div>
          <div>치팅데이는 다이어트를 오래 지속하기 위한 좋은 방법중 하나입니다.</div>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-row dense>

            <v-col md="12" cols="12" align="right">
              <v-row dense>
                <v-col cols="7" align="left">
                  다이어트
                </v-col>
                <v-col cols="5" align="right">
                  시작 날짜 : {{ date }}
                </v-col>
              </v-row>


              <v-row dense>

                <v-col md="4" cols="12" v-for="(each, i) in kcal_data" :key="i">
                  <v-card outlined :dark="each.week == weeks" class="elevation-0 pa-3 pt-1 pb-1 ma-3 mt-0 mb-0">
                    <v-row dense>
                      <v-col cols="6" class="subtitle-2" align="left">
                        {{ each.week }} 주차
                      </v-col>
                      <v-col cols="6" class="subtitle-2" align="right">{{ each.calory }}Kcal</v-col>
                      <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #70AD47">{{ each.carb }} kcal</v-col>
                      <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #4472C7">{{ each.protein }} kcal</v-col>
                      <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #ED7D31">{{ each.fat }} kcal</v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>

              <v-row dense v-if="fat_minus_alert">
                <v-col cols="12">
                  지방 섭취 칼로리가 너무 줄어 듭니다. 이후 유지기간을 가지세요.
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="body-2 pa-4 ma-3" style="border-radius:10px; border:4px solid #dddddd;">
          <div>추정치입니다.</div>
          <div>체지방률이 높은 경우 (활동대사량) - (먹는양) 크기를 늘리셔도 됩니다.</div>
          <div>(활동대사량) - (먹는양)이 크면, 다이어트가 빠르지만 근손실 위험이 있습니다.</div>
          <div>(활동대사량) - (먹는양)의 이상적인 숫자는 500Kcal, 많게는 700Kcal가 될 수 있습니다.</div>
          <div>그 이상 늘릴경우 근손실의 우려가 있습니다.</div>
          <div>근손실은 나이, 호르몬, 근력운동, 활동량, 식단과 관련이 있습니다.</div>
          <div>다이어트를 오래해서 컨디션이 안좋아질 경우에는 1달 '유지기간'을 가지고 다이어트에 다시 돌입하면 좋습니다.</div>
          <div>다이어트 종료후 글리코겐이 재충전 되면서 1kg에서 많게는 2~3kg체중이 증가 될 수 있습니다.</div>
        </v-card>

      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex';

import '../../common/common.js';

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      // diet
      date: "",
      weeks: 1,
      show_desc: false,
      weekly_calory_delta: 50,
      calory_delta: 500,
      weight_init: 0,
      fatrate_init: 0,
      fatrate_want: 0,
      weight_target: 0,
      weight_delta: 0,
      day_diet: 0,
      headers: [
        { text: '주', align: 'center', value: 'week', sortable: false, width:"50", },
        { text: 'Kcal', align: 'right', sortable: false, value: 'calory', },
        { text: '탄수화물', align: 'right', value: 'carb', sortable: false, },
        { text: '단백질', align: 'right', value: 'protein', sortable: false, },
        { text: '지방', align: 'right', value: 'fat', sortable: false,  },
      ],
      kcal_data: [

      ],
      calory_calculated: 0,
      fat_minus_alert: 0,
      cheat_data: [

      ],
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      data: "data",
      mobile: "mobile",
      loaded: "loaded",
      dietstatus: "dietstatus",
      activity: "activity",
      tdee: "tdee",
    }),
    getLoaded: function() {
      return this.$store.getters.loaded;
    },
    getWeight: function() {
      return this.$store.getters.data.weight;
    },
    getFatrate: function() {
      return this.$store.getters.data.fat;
    },
  },
  watch: {
    getLoaded: async function(v, oldv) {
      if ( v == true ) {
        await this.reload_info();
      }
    },
    getWeight: async function(v, oldv) {
      await this.reload_info();
    },
    getFatrate: async function(v, oldv) {
      await this.reload_info();
    },
  },
  // beforeUpdate: async function() {
  //   this.reload_fatrate();
  // },
  mounted: async function() {
    if ( this.loaded ) {
      await this.reload_info();
    }
  },
  methods: {
    reload_info: async function() {
      if ( firebase.auth().currentUser ) {
        await this.get_info();
        await this.get_cheat();
        await this.reload_fatrate();
      }
    },
    get_cheat: async function() {
      if ( this.dietstatus != 1 ) return;
      // load initial data
      const id_token = await firebase.auth().currentUser.getIdToken();
      const data_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/cheating', {
        params: { email: this.user.data.email, date: this.date },
        headers: {'id_token': id_token},
      });

      this.cheat_data = [];

      for ( let each of data_.data) {
        this.cheat_data.push({
          _id: each._id,
          date: each.date,
          total: each.calculated_calory,
          carb: each.carb,
          protein: each.protein,
          fat: each.fat,
        });

      }

    },
    get_info: async function() {

      // load initial data
      const id_token = await firebase.auth().currentUser.getIdToken();
      const diet_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/diet', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      const set_init = async () => {
        if ( this.data.weight == 0 ) {
          setTimeout(() => { set_init(); }, 500);
        } else {
          this.weight_init = this.data.weight;
          this.fatrate_init = this.data.fat;
          if ( this.data.calory_delta < 0 ) {
            this.calory_delta = Math.abs(this.data.calory_delta);
          } else {
            this.calory_delta = 500;
          }
          this.fatrate_want = 0;
          this.weekly_calory_delta = 50;
          this.calory_calculated = this.tdee;
        }
      }

      if ( diet_.data.length > 0 ) {
        // already data
        let latest_diet = diet_.data[diet_.data.length-1];

        if ( latest_diet.date_f != "" ) {
          await set_init();
        } else {
          this.weight_init = latest_diet.weight;
          this.fatrate_init = latest_diet.fatrate;
          this.fatrate_want = latest_diet.fatrate_want;
          this.calory_delta = latest_diet.calory_delta_init;
          this.weekly_calory_delta = latest_diet.calory_delta_weekly;
          this.calory_calculated = latest_diet.calculated_calory;
          this.date = latest_diet.date;
        }
      } else {
        await set_init();
      }
      this.weeks = 1;
      if ( this.date != "")
        this.weeks = this.weeks + (this.$moment().diff(this.$moment(this.date, "YYYY-MM-DD"), 'weeks'));
    },

    reload_fatrate: async function() {
      this.kcal_data = [];
      if ( this.fatrate_want == 0 || this.calory_delta == 0 )
        return;

      this.weight_target = Number(Number(this.weight_init * ((100 - this.fatrate_init)/100)/((100 - this.fatrate_want)/100)).toFixed(1));
      this.weight_delta = Number(Number(this.weight_init - this.weight_target).toFixed(1));

      this.day_diet = Number(Number(this.weight_delta*7000/this.calory_delta).toFixed(1));

      let weeks = this.day_diet / 7;

      if ( !isNaN(weeks) && weeks ) {

        let i = 0;
        let carb_init_ = 0;
        let diff = 0;

        while ( i < (weeks+diff) ) {

          let week_sday = this.$moment(this.date, "YYYY-MM-DD").add(7*i, 'days').format("YYYY-MM-DD");
          let week_eday = this.$moment(this.date, "YYYY-MM-DD").add(7*(i+1), 'days').format("YYYY-MM-DD");

          for ( let each of this.cheat_data ) {
            if ( week_sday <= each.date && week_eday > each.date ) {
              diff++;
            }
          }

          const calory_ = Math.round((this.calory_calculated - this.calory_delta - (i - diff) * this.weekly_calory_delta)/10)*10;

          let carb_ = 0;

          if ( i == 0 ) {
            carb_ = Math.round((calory_ * this.data.food.carb.percentage/100)/10)*10;
            carb_init_ = carb_;
          } else {
            carb_ = Math.round((carb_init_ - ( this.weekly_calory_delta * (i - diff) * (this.data.food.carb.percentage/100) / (this.data.food.carb.percentage/100 + this.data.food.fat.percentage/100)))/10)*10;
          }

          //carb_ = Math.round((calory_ * this.data.food.carb.percentage/100)/10)*10;
          let protein_ = this.data.food.protein.kcal;

          let t_ = {
            week: i+1,
            calory: (Math.round(calory_/10)*10).format(),
            carb: (carb_).format(),
            protein: (Math.round((protein_)/10)*10).format(),
            fat: (Math.round((calory_ - carb_ - protein_)/10)*10).format(),
          };

          i++

          if ( (calory_ - carb_ - protein_) < 0 ) {
            this.fat_minus_alert = 1;
            continue;
          }

          this.kcal_data.push(this.$lodash.cloneDeep(t_));

        }

      }
    },
    changeCaloryDelta: async function(v) {
      //await this.reload_fatrate();
    },
    changeWeeklyCaloryDelta: async function(v) {
      //await this.reload_fatrate();
    },
    changeFatrateWanted: async function(v) {
      this.fatrate_want = v;
      //await this.reload_fatrate();
    },
    save: async function(v) {
      if ( this.tdee == 0 ) {
        alert('활동 정보를 먼저 입력하세요.');
        return;
      }

      if ( this.date != "" ) {
        alert("이미 시작된 다이어트가 있습니다.");
        return;
      }
      if ( this.dietstatus == 1 ) {
        alert("다이어트 중입니다. 다이어트를 종료하고 시작하세요");
        return;
      }

      if ( this.dietstatus == 2 ) {
        alert("유지어트 중입니다. 유지어트를 종료하고 시작하세요");
        return;
      }

      if ( this.fatrate_want == 0 || this.fatrate_want == "" ||
        this.calory_delta == 0 || this.calory_delta == "" ||
        this.weekly_calory_delta == 0 || this.weekly_calory_delta == "") {

        alert("필요한 값을 모두 넣어주세요");
        return;

      }

      if ( this.fatrate_want >= this.fatrate_init ) {
        alert("원하는 체지방률이 현재 체지방률 보다 높습니다.");
        return;
      }

      let activity_time_ = 0;
      for ( let e of this.activity ) {
        activity_time_ += e.time;
      }

      if ( activity_time_ < 24 ) {
        alert("활동 정보는 24시간을 입력해주세요.");
        return;
      }

      if ( ( this.weight_init < 70 || this.user.sex == 'F' ) && this.calory_delta > 500 ) {
        alert("시작시 줄일 칼로리 적정값은 500입니다.");
      }

      if ( ( this.weight_init < 70 || this.user.sex == 'F' ) && this.weekly_calory_delta > 50 ) {
        alert("매주 줄일 칼로리 적정값은 50입니다.");
      }

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/diet', {
        email: this.user.data.email,
        weight: this.weight_init,
        fatrate: this.fatrate_init,
        fatrate_want: this.fatrate_want,
        calory_delta_init: this.calory_delta,
        calory_delta_weekly: this.weekly_calory_delta,
        calculated_calory: this.tdee,
      }, {
        headers: {'id_token': id_token},
      });

      this.date = this.$moment().format("YYYY-MM-DD");

      this.$store.dispatch("fetchDietStatus", 1);

      await this.reload_fatrate();
    },
    finish: async function(v) {
      // if ( this.date == "") {
      //   alert("시작된 다이어트가 없습니다.");
      //   return;
      // }
      if ( !confirm("진행중인 다이어트가 있습니다. 종료하겠습니까?") ) return;
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/diet/finish/', {
        email: this.user.data.email,
        date: this.date,
      }, {
        headers: {'id_token': id_token},
      });

      this.weight_init = this.data.weight;
      this.fatrate_init = this.data.fat;
      if ( this.data.calory_delta < 0 ) {
        this.calory_delta = Math.abs(this.data.calory_delta);
      } else {
        this.calory_delta = 500;
      }
      this.fatrate_want = 0;
      this.weekly_calory_delta = 50;
      this.date = "";
      this.fat_minus_alert = 0;

      this.$store.dispatch("fetchDietStatus", 0);

      this.cheat_data = [];

      await this.reload_fatrate();
    },
    cheat: async function() {
      if ( this.dietstatus != 1 ) {
        alert("다이어트 중이 아닙니다. 다이어트 중에만 치팅이 가능합니다.");
        return;
      }

      let tdee_ = Math.round(Number((this.weight_init * (100 - this.fatrate_init)/100 * 21.6 + 370)*1.35)/10)*10;

      let diet_period_ = (this.$moment().diff(this.$moment(this.date, "YYYY-MM-DD"), 'days'));

      if ( diet_period_ < 7 ) {
        alert("다이어트 시작 일주일이 지난 후 치팅데이가 가능합니다.");
        return;
      }

      if ( this.cheat_data.length > 0 ) {
        let last_cheat_date_ = this.cheat_data[this.cheat_data.length-1].date;
        let diff_last_cheat_date_ = (this.$moment().diff(this.$moment(last_cheat_date_, "YYYY-MM-DD"), 'days'));

        if ( diff_last_cheat_date_ < 7 ) {
          alert("이전 치팅데이로부터 일주일이 지난 후 치팅데이가 가능합니다");
          return;
        }
      }

      if ( diet_period_ > 30 )
        tdee_ -= Math.round(Number(this.weekly_calory_delta * (diet_period_ - 30)/7)/10)*10;

      let tcal1_ = (this.data.muscle * 40*180/1000)*4 + tdee_;
      let carb1_ = tcal1_*(60)/100;
      let protein1_ = this.weight_init*2*4;
      let fat1_ = (tcal1_ - carb1_ - protein1_);

      const id_token = await firebase.auth().currentUser.getIdToken();
      const r = await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/cheating', {
        email: this.user.data.email,
        weight: this.weight_init,
        fatrate: this.fatrate_init,
        tdee: tdee_,
        muscle: this.data.muscle,
        diet_period: diet_period_,
        calory_delta_weekly: this.weekly_calory_delta,
        //carb_rate: this.carb_rate,
        calculated_calory: tcal1_,
        carb: carb1_,
        protein: protein1_,
        fat: fat1_,
      }, {
        headers: {'id_token': id_token},
      });

      this.cheat_data.push({
        _id: r.data._id,
        total: tcal1_,
        carb: carb1_,
        protein: protein1_,
        fat: fat1_,
      });

      //this.date = this.$moment().format("YYYY-MM-DD");

      //this.$store.dispatch("fetchDietStatus", 3);
    },
    del_cheat: async function(_id, idx) {
      confirm("삭제하시겠습니까?") && this.cheat_data.splice(idx, 1);
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/cheating/', {
          data: {
            email: this.user.data.email,
            cheat_id: _id,
          },
          headers: {'id_token': id_token},
      });
    }

  }
}
</script>

<style scoped>

.v-data-table td {
  padding:0 !important;
  padding-right: 10px !important;
  min-height:15px !important;
  height: 25px !important;
}

</style>
